<template>
  <v-container class="my-6" fluid>
    <v-card icon="mdi-clipboard-text" icon-small color="accent">
      <div class="card-header-padding">
        <h5 class="font-weight-bold text-h5 text-typo mb-0">
          Шилжилт хөдөлгөөн xийгдсэн багшийн мэдээлэл (2024.01.01-с хойш)
        </h5>
        <p class="text-sm text-body mb-0">
          Энэxүү мэдээллийг <span class="red--text">ESIS</span> системээс шууд
          татаж байна. ESIS системийн хариу үйлдлээс хамааран <span class="red--text">2-3 минут</span> болно!
        </p>
        <h3 v-if="errorMessage">{{ errorMessage }}</h3>

      </div>
      <v-row class="mb-6 mt-0 mx-2">
        <v-col v-if="newStudents" cols="6" lg="6" md="6" sm="6" class="text-start"><span class="red--text">{{
          newStudents.length }} </span>
          <small>багшийн бүртгэл олдсон</small>

          <v-text-field clearable v-model="search" append-icon="mdi-magnify" label="Xайx" single-line
            hide-details></v-text-field>
        </v-col>
        <v-col cols="6" lg="6" md="6" sm="6" class="text-end">
          <p class="mb-0">
            <small>Мэдээллийг татсан:
              <span class="red--text">{{
               formatDate(this.userData.school._es_movedStudents_readAt)
              }}</span></small>
          </p>
          <v-btn data-title2="Багш нарын мэдээллийг ESIS системээс шинэчилж татаx" @click="_callMovement" elevation="0"
            :ripple="false" height="43" class="font-weight-bold text-capitalize 
            ms-auto btn-danger bg-gradient-info py-3 px-6 ms-3 mr-2">Мэдээлэл татаx</v-btn>
        </v-col>
      </v-row>
      <v-progress-linear v-if="loading" color="red" height="6" indeterminate></v-progress-linear>
      <v-data-table v-if="movedStudents" :headers="headerNames" :items="newStudents" :search="search" hide-details
        hide-default-footer :items-per-page="-1">
        <template v-slot:item.actions="{ item }">
          <v-btn v-if="item.addedAlready == false" class="bg-gradient-success" @click="_saveStudent(item)" small dark>
            Сурагч+
          </v-btn>
          <v-btn v-else disabled text @click="_saveStudent(item)" small class="text-capitalize">
            нэмэгдсэн
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { sync } from "vuex-pathify";
// import AuthService from "@/services/auth-service.js";
// const fb = require("@/firebaseConfig.js");
// import Vue from "vue";
import axios from "axios";
import moment from "moment";
// const fb = require("@/firebaseConfig.js");
export default {
  data: () => ({
    loading: false,
    search: "",
    headerNames: null,
    movedStudents: null,
    snackbar: false,
    uName: null,
    pWord: null,
    token: null,
    errorMessage: null,
  }),
  computed: {
    ...sync("*"),
    newStudents() {
      var list = [];
      var counter = 0;
      if (this.movedStudents) {
        this.movedStudents.forEach((student) => {
          if (student.ACTION_ID == 15) {
            student.index = ++counter;
            list.push(student);
          }
        });
      }
      return list;
    },
  },
  created() {
    this.userData.school.ref.collection("moved-teachers").orderBy("FIRST_NAME", "asc").onSnapshot(docs => {
      this.movedStudents = []
      docs.forEach(doc => {
        let nstud = doc.data()
        nstud.id = doc.id
        nstud.ref = doc.ref
        this.movedStudents.push(nstud)
      })
    })
  },
  mounted() {
    this.headerNames = [
      {
        text: "No",
        align: "end",
        value: "index",
        sortable: true,
        width: "1%",
        fixed: true,
      },
      {
        text: "",
        value: "avatar",
        width: "1%",
        fixed: true,
      },
      {
        text: "Нэр",
        align: "start",
        sortable: true,
        value: "FIRST_NAME",
        fixed: true,
      },
      {
        text: "Овог",
        align: "start",
        sortable: true,
        value: "LAST_NAME",
      },
      {
        text: "Анги",
        align: "start",
        sortable: true,
        value: "STUDENT_GROUP_NAME", //className
      },


      {
        text: "ACTION_NAME",
        align: "start",
        sortable: false,
        value: "ACTION_NAME",
      },
      // {
      //   text: "ACTION_ID",
      //   align: "start",
      //   sortable: true,
      //   value: "ACTION_ID",
      // },

      {
        text: "APPROVAL_STATUS",
        align: "start",
        sortable: false,
        value: "APPROVAL_STATUS",
      },
      {
        text: "PROGRAM_STATUS",
        align: "start",
        sortable: false,
        value: "PROGRAM_STATUS",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
        class: "text-secondary font-weight-bolder opacity-7",
      },
    ];
  },
  methods: {
    _callMovement() {
      if (this.userData.school._esisUserName &&
        this.userData.school._esisPword) {
        this.$swal({
          title: "ESIS системээс мэдээллийг шинэчлэн татаx уу?",
          text: "Шилжилт хөдөлгөөнтэй сурагчдын мэдээллийг татаж, системд xадгална.",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.uName = this.userData.school._esisUserName;
            this.pWord = this.userData.school._esisPword;
            this.loading = true
            axios
              .post(
                "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
                {
                  username: this.uName,
                  password: this.pWord,
                }
              )
              .then((res) => {
                console.log("resresresresresresresres", res)
                this.loading = true
                axios
                  .post(
                    "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/getEsis",
                    {
                      url: "https://hub.esis.edu.mn/svc/api/hub/teacher/movements/2024-01-01",
                      token: res.data.token,
                      
                    }
                  )
                  .then(async (res) => {
                    this.loading = false
                    console.log(res.data,"res")
                    if (res.data["SUCCESS_CODE"] == 200) {
                      console.log(res.data["RESULT"],"teachers")
                      this.movedStudents = [];
                      this.userData.school.ref.update({_es_movedStudents_readAt: new Date()})
                      //var batch = fb.db.batch()
                      res.data["RESULT"].forEach(async (teacher) => {
                        console.log(teacher)
                        // var gg = await this.userData.school.ref.collection("departments-"+this.userData.school.currentYear)
                        //   .doc("department-"+student.ACADEMIC_LEVEL).collection("programs").doc(student.STUDENT_GROUP_ID.toString()).get()
                        
                        //   if(!gg.exists){
                        //     student.addedAlready = false;
                        //       var ff = this.movedStudents.find(ss => ss.PERSON_ID == student["PERSON_ID"])
                        //       if (!ff) {
                        //         batch.set(this.userData.school.ref.collection("moved-students")
                        //         .doc(student["PERSON_ID"].toString()),student, {merge:true})
                        //         // this.movedStudents.push(student);
                        //       }
                        //   }else student.addedAlready = false;
                        //   console.log(student.firstName, student.addedAlready)
                      });
                    }
                  });
              });
          }
        })
      }
    },
    _saveStudent(item) {
      this.$swal({
        title: item.LAST_NAME + " сурагчийг "+ item.STUDENT_GROUP_NAME +" бүлэгт нэмэх үү?",
        text: "Шинээр системд xадгална.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var gg = await this.userData.school.ref.collection("departments-"+this.userData.school.currentYear)
          .doc("department-"+item.ACADEMIC_LEVEL).collection("programs").doc(item.STUDENT_GROUP_ID.toString()).get()

          if(gg.exists){
            item["classGroup-"+this.userData.school.currentYear] = gg.ref;
            item["classGroupName-"+this.userData.school.currentYear] = item.STUDENT_GROUP_NAME
                  ? item.STUDENT_GROUP_NAME
                  : null;
            item.role = "student";
            item.roleName = "Сурагч";
            item.readfrom_esis = true;
            item.email = item.EMAIL ? item.EMAIL : null;
            item.readfrom_esis = true;
            item.departmentIndex = parseInt(item.ACADEMIC_LEVEL);
            item.classGroupName = item.STUDENT_GROUP_NAME;
            item.deleted = false;
            item.created = new Date();
            item.lastName = item.LAST_NAME ? item.LAST_NAME : null;
            item.firstName = item.FIRST_NAME ? item.FIRST_NAME : null;
            
            this.userData.school.ref
              .collection("students-" + this.userData.school.currentYear)
              .doc(item.PERSON_ID.toString()).set(item, { merge: true }).then(() => {
                console.log(item.ref.path)
                item.ref.delete().then(()=>{
                  this.$swal(item.firstName + " сурагч "+ item.STUDENT_GROUP_NAME +" бүлэгт амжилттай нэмэгдсэн")
                })
              })
          }
        }
      })
    },
    formatDate(val) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        let date = val.toDate();
        return moment(date).fromNow();
      }
      return "татаагүй";
    },
  },
};
</script>
